/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react"

const useAudioPlayer = ({ audioUrl }) => {
    const audioRef = useRef(new Audio(audioUrl))

    const [isPaused, setIsPaused] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [totalAudioDuration, setTotalAudioDuration] = useState(0)
    const [currentAudioDuration, setCurrentAudioDuration] = useState(0)

    useEffect(() => () => {
        audioRef.current.pause()
    }, [])

    useEffect(() => {
        const hasAudioRef = audioRef.current

        if(!hasAudioRef) return

        const audioElement = audioRef.current

        setIsLoading(false)

        audioElement.addEventListener('play', () => {
            setIsPaused(false)
        })
        
        audioElement.addEventListener('pause', () => {
            setIsPaused(true)
        })

        audioElement.addEventListener('timeupdate', (e) => {
            setCurrentAudioDuration(Math.floor(e.target.currentTime))
        })

        audioElement.addEventListener('loadeddata', (e) => {
            setTotalAudioDuration(e.target.duration)
        }, { once: true })
    }, [audioRef.current])
    
    const changeDuration = useCallback((newTime) => {
        if(isPaused) audioRef.current.play()

        audioRef.current.currentTime = newTime
    }, [isPaused])

    const togglePauseState = useCallback(() => {
        if (isPaused) {
            return audioRef.current.play()
        }

        audioRef.current.pause()
    }, [isPaused])

    return {
        isPaused,
        isLoading,
        togglePauseState,
        changeDuration,
        totalAudioDuration,
        currentAudioDuration,
    }
}

export default useAudioPlayer