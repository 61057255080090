import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import * as serviceWorker from './services/serviceWorker';
import { default_client } from './services/api';
import firebase from 'firebase/app';

import { isMobile } from './utils/device'

import PushContextProvider from './context/pushContext';
import PWAContextProvider from './context/pwaContext';

if (firebase.messaging.isSupported()) {
	const firebaseConfig = {
		apiKey: "AIzaSyDCw9gUHqy2na3xnvgwsLJ9JogBpNcvnOk",
		authDomain: "zoomoo-producao.firebaseapp.com",
		projectId: "zoomoo-producao",
		storageBucket: "zoomoo-producao.appspot.com",
		messagingSenderId: "1008832433798",
		appId: "1:1008832433798:web:5ad8601cb4af49f4b00b07",
		measurementId: "G-CW3Z9WNTFW"
	};

	firebase.initializeApp(firebaseConfig);
	firebase.analytics();

	const messaging = firebase.messaging();
	messaging.onMessage(function (payload) {
		if (payload?.data?.title && payload?.data?.body) {
			const { title, body } = payload?.data;

			const notification_options = {
				body,
				icon: `${default_client + '/logo512.png'}`,
			}

			if (isMobile) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					if (registrations) {
						registrations[0].showNotification(title, notification_options);
					}
				});
			} else {
				new Notification(title, notification_options);
			}
		}

	});
}


ReactDOM.render(
	<PushContextProvider>
		<PWAContextProvider>
			<App />
		</PWAContextProvider>
	</PushContextProvider>
	, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
