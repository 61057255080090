import React, {  } from 'react';
import ImageCopyright from './../../assets/images/anim_selo_copyright_updated.gif'
import './../../assets/styles/Copyrights.css'


const Copyrights = () => {
    return (
        <img className="selo_copyright" src={ImageCopyright} />
    );
}

export default Copyrights;