import React from 'react'

import ProgressBar from './components/ProgressBar'
import ControlButtons from './components/ControlButtons'

import useAudioPlayer from './hooks/useAudioPlayer'

import './styles.css'

const AudioPlayer = ({ audioUrl }) => {
    const {
        isPaused,
        isLoading,
        togglePauseState,
        changeDuration,
        totalAudioDuration,
        currentAudioDuration,
    } = useAudioPlayer({ audioUrl })

    return (
        <div className='audio-player-wrapper'>
            {isLoading ? (
            <>
                <p>loading...</p>
            </>
            ) : (
            <>
                <ProgressBar 
                    onChangeDuration={changeDuration}
                    totalDuration={totalAudioDuration} 
                    currentDuration={currentAudioDuration} 
                />

                <ControlButtons 
                    isPaused={isPaused}
                    togglePaused={togglePauseState}
                    onChangeDuration={changeDuration}
                    currentDuration={currentAudioDuration}
                />
            </>
            )}
        </div>
    )
}

export default AudioPlayer