import React from 'react'

import './styles.css'

const ControlButtons = ({ isPaused, togglePaused, onChangeDuration, currentDuration }) => {
    return (
        <div className='control-buttons-wrapper'>
            <button
                className='control-button time-control-button rewind-button'
                onClick={() => onChangeDuration(currentDuration - 15)}
            />

            <button
                className={`control-button ${isPaused ? 'play-button' : 'pause-button'}`}
                onClick={togglePaused}
            />
            
            <button
                className='control-button time-control-button forward-button'
                onClick={() => onChangeDuration(currentDuration + 15)}
            />
        </div>
    )
}

export default ControlButtons