import React from "react";
import { ImMoveUp } from "react-icons/im";
import './styles.css';

const FullscreenAlert = () => {
  return (
    <div className="fullscreen-alert">
      <ImMoveUp color="black" size={50} />
      <p>arraste para cima para maximizar a tela!</p>
    </div>
  );
}

export { FullscreenAlert };