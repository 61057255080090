import React from "react";
import "./../../assets/styles/AuthTitle.css";

const AuthTitle = () => {
  return (
    <div className="auth_space" />
  );
};

export default AuthTitle;
