import React, { useState, forwardRef, useImperativeHandle } from 'react';
import imgLoading from './../../assets/images/p-loader.gif';
import "./../../assets/styles/Processing.css"


const Processing = forwardRef((props, ref) => {
    const [isProcessing, setProcessing] = useState(false);
    useImperativeHandle(ref, () => ({

        show() {
            setProcessing(true);
        },
        hide() {
            setProcessing(false);
        }

    }));
    const displayControl = {
        display: isProcessing ? 'block' : 'none'
    }
    return (
        <div id={'background'} className="processing_background" style={displayControl}>
            <img src={imgLoading} alt={"Loading..."} />
        </div>
    );
});

export default Processing;

