import firebase from 'firebase';

const userAgent = window.navigator.userAgent.toLowerCase();

export const isMobile = /iphone|ipad|ipod|android/i.test(userAgent);

export const isIOSDevice = (/iPad|iPhone|iPod/i.test(navigator.platform) ||
(navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1)) &&
!window?.MSStream;

export const isIPhone = /iphone/i.test(userAgent);

export const isSafari = /version/.test(userAgent);

export const isPWAInstaled = window.matchMedia('(display-mode: standalone)').matches;

export const isPWAInstaledIOS = !!window.navigator.standalone;

export const isFirebaseSupported = firebase.messaging.isSupported();

export const isSafariNotifficationSupported = 'safari' in window && 'pushNotification' in window.safari;

export const isSupportedInstallPrompt = !!("onbeforeinstallprompt" in window);
