import firebase from 'firebase';

export const askUserPermission = () => {
	return new Promise((resolve, reject) => {
		Notification.requestPermission().then(result => {
			console.log(`Result: ${result}`)
			if (result === 'denied') {
				reject('Permission wasn\'t granted. Allow a retry.');
				// return;
			}
			if (result === 'default') {
				reject('The permission request was dismissed.');
				// return;
			}

			const messaging = firebase.messaging();
			messaging
				.getToken(messaging, {
					vapidKey: 'BCdRjailjv6LeqX0O7z9wIRr_5UstzhQP20oEXshnfbpG-BPVER5hsXrcLSxNUQMY0vvQPFJOHNpUo32cMEw9R0'
				})
				.then((currentToken) => {
					if (currentToken) {
						resolve(currentToken);
						// console.log('token do usuario:', currentToken);
					} else {
						reject('No registration token available. Request permission to generate one.');
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	});
};

// messaging.onMessage(function(payload) {
// 	// const data = JSON.parse(payload.data.notification);
// 	new Notification('teste');
// });