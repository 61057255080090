import React, { useState, useEffect } from "react";
import "./../../assets/styles/ReadBook.css";
import { ReactComponent as AudioIcon } from "../../assets/images/sons.svg";
import { ReactComponent as StorytellerIcon } from "../../assets/images/narracao.svg";
import Loader from "react-loader-spinner";

const AudioButton = (props) => {
  const { type, url, fxIndex } = props;

  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const audio = document.getElementById(
      type === "story" ? "audioStory" : `audio${fxIndex}`
    );
    const percentages = document.getElementById(
      type === "story" ? "buffStory" : `buff${fxIndex}`
    );

    if (url) {
      playing ? playMusic(audio) : audio.pause();
    }
  }, [playing]);

  const playMusic = (audio) => {
    var playPromise = audio.play();
    !loaded && setLoading(true);
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  };

  const toggle = () => setPlaying(!playing);

  const iconType = () => {
    if (!loading && !playing) {
      return type === "story" ? <StorytellerIcon /> : <AudioIcon />;
    }
    return null;
  };

  const getLoader = () => {
    if (!loaded && loading) {
      return <Loader type="Oval" color="#000000" height={30} width={30} />;
    }

    if (playing && loaded) {
      return <Loader type="Audio" color="#000000" height={30} width={30} />;
    }

    return null;
  };

  return (
    <>
      <audio
        onLoadedData={() => {
          setLoading(false);
          setLoaded(true);
        }}
        preload="none"
        onEnded={() => { 
          setPlaying(!playing)
        }}
        id={type === "story" ? "audioStory" : `audio${fxIndex}`}
      >
        <source src={url} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <button
        className="reader_pages_button"
        onClick={toggle}
        style={{ marginRight: 10 }}
      >
        {iconType()}
        {getLoader()}
      </button>
    </>
  );
};

export default AudioButton;
