import React from "react";

const SeuLivroAqui = props => {
  return (
    <div className="modal">
      <h2>SEU LIVRO AQUI</h2>
      <p>
        Se você é autor de literatura para a infância e quer ver seu livro no
        Conta Outra Vez! fale com a gente. Gostamos muito de conhecer e
        conversar com novos autores!
      </p>
      <p>
        <a href="mailto:contato@contaoutravez.com.br">
          contato@contaoutravez.com.br
        </a>
      </p>
      <div className="modal-button-container">
        <button onClick={props.onRequestClose}>FECHAR</button>
      </div>
    </div>
  );
};

export default SeuLivroAqui;
