import React from 'react';
import Drawer from 'react-drag-drawer';

const HelpModal = ({ show_help, toggle_help }) => {
	return (
		<Drawer dontApplyListeners={true} open={show_help} onRequestClose={() => toggle_help(false)}>
			<div className="modal" style={{ width: '250px', height: '435px' }}>
				<div>
					<p>
						Se você recebeu o acesso ao Conta Outra Vez! de sua operadora de celular, informe seu número com
						DDD e os três primeiros dígitos do seu CPF para entrar. Mas se você recebeu o acesso de seu
						provedor de internet banda larga, então ajuste o seletor para a posição “Entrar com CPF/CNPJ”,
						informe o CPF ou CNPJ cadastrado e marque “não sou um robô”.
					</p>
					<button onClick={() => toggle_help(false)}>FECHAR</button>
				</div>
			</div>
		</Drawer>
	);
};
export default HelpModal;
