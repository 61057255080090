const useProgressBar = ({ onChangeDuration }) => {
    const handleOnChangeDuration = e => {
        onChangeDuration(e.target.value)
    }

    return {
        handleOnChangeDuration
    }
}

export default useProgressBar