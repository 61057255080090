
class DataManager {
    constructor(props) {
        this.token = this.initialValue('token', props);
        this.currentMonthName = this.initialValue('currentMonthName', props);
        this.currentYear = this.initialValue('currentYear', props);
        this.openCollection = this.initialValue('openCollection', props);
        this.listData = this.initialValue('listData', props);
    }
    initialValue(param, data) {
        let output = undefined;
        if (typeof data === 'object') {
            output = param in data ? data[param] : undefined;
        }
        return output;
    }
}

export default DataManager;
