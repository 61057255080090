const secondsToTime = (seconds) => {
    const h = Math.floor(seconds/ 3600).toString().padStart(2,'0')
    const m = Math.floor(seconds % 3600 / 60).toString().padStart(2,'0')
    const s = Math.floor(seconds % 60).toString().padStart(2,'0')

    return +h 
    ? h + ':' + m + ':' + s 
    : m + ':' + s
}

export default secondsToTime