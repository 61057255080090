import React, { useState, useEffect } from "react";
import "./../../assets/styles/DeviceOrientation.css";

const DeviceOrientation = ({ alert, landscape }) => {
    if (!alert) {
        return null;
    }
    return (
        <div className='box'>
            <div className='container'>
                <img src={require(`./../../assets/images/orientation-to-${landscape ? 'horizontal' : 'vertical'}.png`)} />
                <span>GIRE SEU APARELHO</span>
            </div>
        </div>
    )
}

export default DeviceOrientation;
